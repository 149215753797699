<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <div class="d-flex flex-wrap justify-center mb-2" style="width: 100%">
      <img src="@/assets/logo/Logo-Verde.png" height="50px" />
    </div>

    <div
      class="d-flex flex-wrap justify-center mb-12"
      style="width: 100%; font-size: 20px; font-weight: 300;"
    >
      <h4 style="font-weight: 400;">
        {{ $t("Genetica") }} · {{ $t("FarmacoGenetica") }}
      </h4>
    </div>

    <!-- <detalle-animado color="5DFFBD"></detalle-animado> -->

    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <div class="text-left">
            <h1 class="display-2 font-weight-bold mb-8" style="color: #1C093A;">
              {{ $t("enviado_correo_de_confirmacion") }}
            </h1>
          </div>
          <p>
             {{$t('se_ha_enviado_un_correo_para')}}
          </p>
 
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DetalleAnimado from "@/components/mylogy/DetalleAnimado.vue";

export default {
  components: {},
  name: "PagesConfirmationSent",
};
</script>
