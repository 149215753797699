<template>
<div class="detalle-animado" :style="cssVars">
  <div class="elemento"></div>
  <div class="elemento"></div>
  <div class="elemento"></div>
  <div class="elemento"></div>
</div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    }
  },
  computed: {
    cssVars() {
      return {
        "--detalle-color": `#${this.color}`
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.detalle-animado {
  display: grid;
  grid-template-rows: 25% 25% 25% 25%;
  grid-template-columns: 25% 25% 25% 25%;
  height: 200px;
  width: 200px;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(-10%,-50%);
  gap: 5px;

  .elemento {
    background: var(--detalle-color);
    box-shadow: 0 5px 20px -10px var(--detalle-color);
  }
  div:nth-child(1) {
    grid-column: 1 / span 3;
    grid-row: 1;
    border-radius: 50vh 50vh 0 50vh;
    animation: detalles-animados 5s infinite alternate;
  }
  div:nth-child(2) {
    grid-column: 3 / span 2;
    grid-row: 2;
    border-radius: 50vh;
    animation: detalles-animados 4s infinite alternate;
  }
  div:nth-child(3) {
    grid-column: 2 / span 1;
    grid-row: 3;
    border-radius: 50vh;
    animation: detalles-animados 6s infinite alternate;
  }
  div:nth-child(4) {
    grid-column: 2 / span 3;
    grid-row: 4;
    border-radius: 0 50vh 50vh;
    animation: detalles-animados 7s infinite alternate;
  }
}

@media (max-width: 768px) {
  .detalle-animado {
    width: 300px;
    height: 300px;
    gap: 20px;
    top: 100%;
    transform: translateY(-90%);
  }
}

@keyframes detalles-animados {
  0% { transform: translateX(0);}
  100% { transform: translateX(20px);}
}
</style>
